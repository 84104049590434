@import '../../scss/mixins';

.ReactModal__Overlay {
    outline: none !important;
    div, span {
        outline: none !important;
    }
}

.feedback-form {
    @include table-header;

    .form-header, .form-stages, .no-form {
        background: #fff;
        border-radius: 8px;
        box-shadow: 10px 10px 25px hsla(0, 0%, 46.7%, 0.5);
        padding: 18px;
        margin: 12px;
    }

    .form-header {
        .dropdown-block  {
            justify-content: flex-end;
        }
        .status-block {
            justify-content: space-between;
            .form-status {
                margin: 0;
                font-size: 14px;
                font-style: italic;
                font-weight: bold;
            }
        }
    }

    .form-stages {
        p {
            font-size: 12px;
            margin-bottom: 0;
            input[type='radio'] {
                margin-right: 5px;
                margin-top: -2px;
                vertical-align: middle;
            }
            .char-count {
                margin-left : 10px;
                float: right;
                color: #999999;
            }
        }
        textarea {
            width: 100%;
            min-height: 80px;
            padding: 10px;
            font-size: 14px;
            border: 1px solid #999999;
            border-radius: 5px;
        }
        .form-info {
            display: flex;
            justify-content: space-between;
            font-weight: bold;
            color: #969696;
            h3 {
                font-size: 12px;
            }
            .updated-date span {
                color: #4f7623;
            }
            .export-doc {
                cursor: pointer;
                display: flex;
                justify-content: flex-end;
                h3 {
                    margin-left: 5px;
                    &:hover {
                        text-decoration: underline;
                    }
                }
            }
        }
        table {
            margin-bottom: 12px;
            th {
                text-transform: uppercase;
                &.heading-col-1 {
                    width: 20%;
                }
                &.heading-col-2 {
                    width: 20%;
                }
                &.heading-col-3 {
                    width: 60%;
                }
            }
            th,
            td {
                border: 1px solid #5f6062;
                &.competence-heading {
                    text-align: center;
                    vertical-align: middle;
                }
                .ratings{
                    font-size: 12px;
                    width: 100%;
                    vertical-align: middle;
                }
            }
            p {
                font-size: 12px;
                font-weight: bold;
                margin-bottom: 5px;
            }
            tbody {
                td {
                    background-color: #FFF;
                }
            }
        }
        .corevalues-table {
            td {
                font-size: 12px;
                font-weight: bold;
                .ratings{
                    width: 100%;
                    vertical-align: middle;
                }
            }
            textarea {
                min-height: 280px;
            }
        }
        .rating-section{
            display: flex;
            flex-direction: row;
            .tooltip-docinfo {
                td {
                    background-color: #222;
                }
            }
        }
        .employee-comments {
            .employee-heading {
                .char-count {
                    margin-left : 10px;
                    float: right;
                    font-size: 12px;
                    font-weight: bold;
                    color: #999999;
                }
            }
        }
        .with-bottom-margin {
            margin-bottom: 10px;
            label {
                display: inline-block;
                margin-bottom: .5rem;
            }
            .rating-radio {
                display: flex;
            }
            .item {
                margin: 5px;
            }
        }
        .buttons {
            display: flex;
            justify-content: flex-end;
            .btn {
                margin: 16px 12px;
            }
        }
        .invalid {
            border: 1px solid #dc3545;
            box-shadow: 0px 0px 8px #dc3545;
        }
        &.read-only {
            .char-count {
                display: none;
            }
            input, textarea {
                background-color: #eeeeee;
                cursor: not-allowed;
            }
            .employee-section.form-not-closed {
                input, textarea {
                    background-color: initial;
                    cursor: auto;
                }
                .char-count {
                    display: block;
                }
            }
        }
    }
    .no-form{
        h4 {
            text-align: center;
        }
    }
    .form-inline {
        display: flex;
        flex-flow: row wrap;
        align-items: center;
        .form-check {
          width: 100%;
        }
    }
    .form-group {
        margin-bottom: 1rem;
    }
    .form-label {
        margin: 0;
    }
}
