@import "scss/custom.scss";
@import "./scss/mixins";
@import "./scss/variables";

.regular-text {
    font-size: 20px;
    font-weight: 300;
}
.btn-outline-primary {
    color: #f37321;
    border-color: #f37321;

    &:hover {
        color: #fff;
        background-color: #f37321;
        border-color: #f37321;
        box-shadow: 2px 2px 10px 0px #5f6062;
        transform: translateY(-2px);
    }
    &:active {
        background-color: #f37321 !important;
        border-color: #f37321 !important;
    }
    &:focus {
        box-shadow: 0 0 0 0.2rem rgba(240, 91, 19, 0.5) !important;
    }
    &:disabled {
        color: #f37321;
        border-color: #f37321;

        &:hover {
            cursor: not-allowed;
            box-shadow: none;
            color: #f37321;
            border-color: #f37321;
            background-color: #fff;
            transform: none;
        }
    }
}

.btn-outline-primary.fixed-background {
    color: #f37321;
    border-color: #f37321;

    &:hover {
        color: #f37321;
        background-color: #fff;
        border-color: #f37321;
        box-shadow: 2px 2px 10px 0px #5f6062;
        transform: translateY(-2px);
    }

    &:active {
        background-color: #fff !important;
        border-color: #f37321 !important;
    }

    &:focus {
        box-shadow: 0 0 0 0.2rem rgba(240, 91, 19, 0.5) !important;
    }

    &:disabled {
        color: #f37321;
        border-color: #f37321;
        &:hover {
            cursor: not-allowed;
            box-shadow: none;
            transform: none;
        }
    }
}

.btn-outline-secondary {
    color: #5f6062;
    border-color: #5f6062;

    &:hover {
        color: #fff;
        background-color: #5f6062;
        border-color: #5f6062;
        box-shadow: 2px 2px 10px 0px #5f6062;
        transform: translateY(-2px);
    }

    &:active {
        background-color: #5f6062 !important;
        border-color: #5f6062 !important;
    }

    &:focus {
        box-shadow: 0 0 0 0.2rem rgba(240, 91, 19, 0.5) !important;
    }

    &:disabled {
        color: #5f6062;
        border-color: #5f6062;

        &:hover {
            cursor: not-allowed;
            box-shadow: none;
            color: #5f6062;
            border-color: #5f6062;
            background-color: #5f6062;
            transform: none;
        }
    }
}

.btn-primary {
    background-color: #f37321;
    border-color: #f37321;
    color: #fff;

    &:hover,
    &:focus,
    &:not(:disabled),
    &:not(.disabled) {
        background-color: #f37321;
        border-color: #f37321;
    }

    &:hover {
        box-shadow: 2px 2px 10px 0px #5f6062;
        transform: translateY(-2px);
    }

    &:active {
        background-color: #f37321 !important;
        border-color: #f37321 !important;
    }
    &:focus {
        box-shadow: 0 0 0 0.2rem rgba(240, 91, 19, 0.5) !important;
    }
    &:disabled {
        &:hover {
            cursor: not-allowed;
            box-shadow: none;
            transform: none;
        }
    }
}

.react-select {
    width: 140px;

    .select__control {
        border: none;
        border-bottom: 1px solid #000;
        border-radius: 0;
        box-shadow: none;
        @include select-control-focused;
        &.select__control--is-focused {
            box-shadow: none;
            border-bottom: 2px solid $primaryColor;
        }
        .select__indicators {
            .select__indicator-separator {
                display: none;
            }
        }
    }
    @include select-menu;
}
.alert {
    display: table;
    margin: auto;
    .btn-close:focus {
        box-shadow: none;
    }
}
.alert-message {
    color: #005f73;
    background-color: #ccf0f8;
    border-color: #b8ebf6;
}
.input[type="checkbox" i] {
    appearance: auto;
}
.btn {
    transition: transform 250ms;
}
.top-50-p {
    top: 50%;
}

.left-45-p {
    left: 45% !important;
}
