.loader {
    background: inherit;
    position: absolute;
    top: 40%;
    left: 47%;

    span {
        width: 100px !important;
        height: 100px !important;
    }
}