@import "./scss/mixins";

.main {
    width: calc(100% - 5rem);
    margin: 0 1rem 0 5rem;
    transition: all 0.4s;
}
.active-sessions-body .main {
    margin: 0;
}
@include media-queries("mobile") {
    .main {
        width: 100%;
        margin-left: 0;
    }
}
