@import '../../scss/mixins';

.team-status {
    .card {
        height: auto;
        width: 250px;
        margin: 0 12px;
        padding: 12px;
        display: flex;
        justify-content: space-around;
        @include cardEffect();

        h4 {
            @include rg-text(18px);
            text-align: center;
        }

        .info {
            display: flex;
            flex-direction: column;
            align-items: center;

            h1 {
                @include md-text(34px);
                font-weight: 500;
                margin: 0;

                &.total {
                    color: #757575;
                }
            }
            .horizontal-line {
                background: #000;
                padding: 1px 24px;
            }
            .btn-link {
                font-weight: 400;
                color: #f05b13;
                text-decoration: none;
                &:hover {
                    color: #ac3f0b;
                    text-decoration: underline;
                }
            }
        }
    }
}
