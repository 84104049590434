.announcement-description {
    a {
        color: #f05b13;
        text-decoration: none;
        background-color: initial;
    }
    button {
        padding: 0 1px;

        &:focus {
            box-shadow: none !important;
        }
    }
}