@import "../../scss/variables";
@import "../../scss//mixins";
.sidebar {
    min-width: 4rem;
    width: 4rem;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    background-color: #fff;
    box-shadow: 20px 20px 25px rgba(0, 0, 0, 0.1);
    border-right: 0.25px solid #d9d9d9;
    z-index: 2;
    transition: 0.3s linear;
    .logo {
        height: 50px;
        margin: 28px 18px 10px 18px;
        color: #4c4d4f;
        svg {
            .name {
                display: none;
            }
        }
    }
    .nav {
        &.nav-scroll {
            flex-wrap: nowrap;
            overflow-y: auto;
            height: 90%;
        }
        .custom-accordion {
            &.active {
                &:hover {
                    background-color: #f7f7f7;
                }
            }
        }
        .nav-link {
            padding: 14px 16px;
            display: flex;
            justify-content: flex-start;
            color: #777777;
            border-left: 4px solid #fff;
            align-items: center;
            &.nav-sub-menu {
                position: relative;
                width: 100%;
                background-color: inherit;
                border-radius: 0;
                padding: 0;
                &:hover {
                    box-shadow: none;
                    transform: none;
                }
                &:active {
                    background-color: inherit !important;
                }
                .accordion-button {
                    padding: 14px 16px;
                }
                .route-name {
                    svg {
                        width: 14px;
                        height: 14px;
                        position: absolute;
                        right: 12px;
                        bottom: 18px;
                        &.rotate {
                            transform: rotate(90deg);
                        }
                    }
                }
            }
            span {
                font-size: 16px;
            }
            svg {
                fill: #777777;
            }
            &:hover {
                background-color: #f7f7f7;
                color: #000;
                svg {
                    fill: #000;
                }
            }
            &.active {
                border-left: 4px solid $primaryColor;
                color: #000;
                background-color: #ededed;
                font-weight: 500;
                svg {
                    fill: #000;
                }
            }
            .route-name {
                display: none;
            }
            svg {
                width: 24px;
                height: 24px;
            }
        }
    }
    .toggle {
        display: none;
    }

    &:hover {
        transition: 0.2s ease-out;
        min-width: 16rem;
        width: 16rem;
        .logo {
            .name {
                display: inline-block;
            }
        }
        .nav {
            .nav-link {
                .route-name {
                    display: inline-block;
                    white-space: nowrap;
                    overflow: hidden;
                    transition: all 0.3s;
                    padding-left: 8px;
                }
            }
        }
    }
}
.active-sessions-body .sidebar {
    display: none;
}
@include media-queries("tablet") {
    .sidebar {
        &:hover {
            min-width: 4rem;
            width: 4rem;

            .route-name {
                display: none !important;
            }
            .logo {
                svg {
                    .name {
                        display: none;
                    }
                }
            }
        }

        .route-name,
        .toggle {
            display: none;
        }

        .logo {
            svg {
                .name {
                    display: none;
                }
            }
        }
        .nav {
            width: 100%;
        }
    }
}
@include media-queries("mobile") {
    .sidebar {
        width: 80%;
        display: none;
        &.active {
            display: flex;
            min-width: 18rem;
            width: 20rem;
            .route-name {
                display: inline-block !important;
            }
        }
        .route-name {
            display: inline-block !important;
            padding-left: 12px;
        }
        .logo {
            display: none;
        }
        .toggle {
            position: absolute;
            top: 0;
            right: 0;
            width: 60px;
            height: 60px;
            display: flex;
            justify-content: flex-end;
            padding: 12px;
            cursor: pointer;
            svg {
                font-size: 26px;
            }
        }
    }
}
