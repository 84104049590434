.pagination {
    display: flex;
    justify-content: center;
    margin-top: 12px;
    .page-link {
        background-color: #fff;
    }
    .page-link:hover {
        color: #f05b13;
        background-color: #e9ecef;
        border-color: #dee2e6;
    }
    .page-item.active .page-link {
        z-index: 3;
        color: #fff;
        background-color: #f05b13;
        border-color: #f05b13;
    }
}