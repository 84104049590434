
@media print {
    .header {
        display: none;
    }
    .sidebar {
        display: none;
    }
    .main {
        margin: 0 auto;
    }
    .feedback-form table thead tr th {
        print-color-adjust: exact;
        -webkit-print-color-adjust: exact;
        background-color: #5f6062 !important;
        color: #fff !important;
    }
    body {
        background-color: #fff !important;
    }
}

.form-pdf {
    transform: scale(1.0);
    transform-origin: top left;
    zoom: 0.7;
    .form-header, .form-stages {
        margin: 5px;
        padding: 5px;
        box-shadow: none;
        background-color: #fff !important;
        border-radius: 0;
    }
    .form-stages {
        p {
            font-size: 12px;
        }
        table {
            width: 100%;
            th {
                &.heading-col-1 {
                    width: 15%;
                }
                &.heading-col-2 {
                    width: 5%;
                }
            }
            p {
                font-size: 12px;
            }
        }
    }
    .form-header {
        .section1 {
            margin-bottom: 5px;
            font-weight: bold;
        }
        .section2 {
            box-shadow: none;
            display: flex;
            font-size: 12px;
            .header-items {
                margin-right: 10px;
            }
            .bold {
                font-weight: bolder;
            }
        }
    }
    .radio-input{
        h6 {
            margin-bottom: 10px;
            margin-right: 16px;
        }
        .radio-buttons {
            display: flex;
            
            p {
                margin-right: 16px;
                margin-bottom: 8px;
                input[type='radio'] {
                    margin-right: 5px;
                }
            }
        }
    }
    .acknowledgement{
        display: flex;
        span {
            margin: 0 10px;
            label {
                margin: 0 5px;
            }
        }
    }
    textarea {
        border: none;
        outline: none;
    }
    .corevalues-table {
        td {
            padding: 0.4em;
        }
        td.specific-behaviors {
            padding: 0.75em;
        }
    }
    div.textAreaDiv {
        white-space: pre-wrap;
        font-weight: normal;
        font-size: 12px;
        overflow-wrap: anywhere;
        min-height: 100px;
    }
}

.goals {
    .goals-heading {
        font-weight: bold;
        margin-bottom: 10px;
        font-size: 12px;
        color: #333;
    }

    .goals-content {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
    }

    .goal-item {
        border: 2px solid #a7a6a6;
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 18%;
        padding: 15px;
        margin-bottom: 20px;
        font-size: 12px;
        border-radius: 8px;
        background-color: #f7f7f7;
        .status {
            font-size: 10px;
        }
    }

    .goal-quarter {
        font-weight: bold;
        margin-bottom: 8px;
        font-size: 12px;
        color: #333;
    }

    .goal-points {
        font-size: 12px;
        color: #555;
    }

    .completed {
        color: #28a745;
    }

    .not-completed {
        color: #dc3545;
    }
}
.feedback-form table thead tr th {
    box-shadow: none;
}
.footer {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #f0f0f0;
    padding: 10px;
    position: fixed;
    bottom: 0;
    width: 100%;
    font-family: 'Arial', sans-serif;
    color: #555;
    font-size: 12px;
}
