@import "../../scss/mixins";
@import "../../scss/variables";
.active-sessions {
    position: relative;
    @include padding();
    @include cardEffect;
    width: 80%;
    margin-left: 10%;
    table {
        position: relative;
        font-size: 14px;
        margin-bottom: 0;
        width: 100%;
        border-spacing: 0;
        overflow-x: auto;
        overflow-y: hidden;
        color: #212529;
        border-collapse: collapse;
    }
    table thead tr {
        border-radius: 8px;
    }
    table thead tr th {
        background: #5f6062;
        color: #fff;
        box-shadow: 0px 15px 20px 0px rgba(119, 119, 119, 0.5);
    }
    th,
    td {
        padding: 10px;
        text-align: center;
        vertical-align: top;
    }
    @include table-header;
    .active-sessions-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 50px;
        margin-bottom: 10px;

        h3 {
            @include md-text(24px);
            font-weight: 500;
        }
    }
    .action-buttons {
        display: flex;
        justify-content: space-around;
        align-items: center;

        .btn {
            margin: 0 5px;
            padding: 10px 20px;
            border-radius: 5px;
            transition:
                background-color 0.3s,
                color 0.3s,
                box-shadow 0.3s;
            border: none;

            &.continue {
                background: #e0e0e0;
                color: #424242;
                box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);

                &:hover {
                    background-color: #c0c0c0;
                    color: #333333;
                    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
                }
            }

            &.destroy {
                background: #8b8686;
                color: #ffffff;
                box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);

                &:hover {
                    background-color: #9a9a9a;
                    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
                }
            }
        }
    }
    .warning-message {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 8px;
        background-color: #e5e5e5;
        border-radius: 12px;
        box-shadow:
            0 20px 36px -8px rgba(14, 14, 44, 0.1),
            0 1px 2px rgba(0, 0, 0, 0.05);
        padding: 20px 12px;
        gap: 12px;
        margin-bottom: 10px;
        // width: 80%;
        .continue-sessions-button {
            margin-left: auto;
        }
        div {
            display: flex;
            flex-direction: column;
            margin-right: auto;
        }
        h1, p {
            margin: 0;
            padding: 5px 0px;
        }
    }
}
